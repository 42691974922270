<template>
  <a-modal v-model="visible" v-if="detail" centered :title="title" :width="600" :maskClosable="false">
    <template slot="footer">
      <a-button @click="closeModal">Close</a-button>
      <a-button type="primary" @click="submitForm" :loading="loading">Confirm</a-button>
    </template>
    <h4>
      <a-icon type="question-circle" theme="filled" :style="{color: detail.status === 1 ? '#ff5500' : '#48C648'}" />
      Are you sure to <b :style="{color: detail.status === 1 ? '#ff5500' : '#48C648'}">{{title.toLowerCase()}}</b> user <i style="color: #0a709c">{{detail.email}}</i>?
    </h4>
  </a-modal>
</template>

<script>
import UsersService from '@/services/app/users.service'

export default {
  name: "ItemFoodsForm",
  data() {
    return {
      visible: false,
      title: null,
      loading: false,
      detail: null,
    }
  },
  methods: {
    showModal(item ) {
      this.visible = true;
      this.detail = {...item}
      if (item.status === 1) {
        this.title = 'Inactive'
      }
      if (item.status === 0) {
        this.title = 'Active'
      }
    },
    closeModal() {
      this.visible = false
      this.detail = null
    },
    submitForm() {
      const params = {
        isActive: this.detail.status !== 1
      }
      this.loading = true
      const mess = this.detail.status === 1 ? 'Inactive user successfully' : 'Active user successfully';
      UsersService.changeStatus(this.detail.id, params).then(res => {
        if (res.code === 1) {
          this.$notification.success({
            message: mess
          })
          this.closeModal()
          this.$emit('fetch')
        } else {
          this.$notification.warning({
            message: res && res.message ? res.message : 'Something went wrong',
          })
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$notification.error({
          message: err && err.message ? err.message : 'Something went wrong',
        })
      })
    },
  }
}
</script>
